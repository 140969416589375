import axios from 'axios'

// Function for fetching every user's projects
export async function myProjects(auth) {
  try {
    const response = await fetch(`${process.env.REACT_APP_SCRUM_BEE_API_URL}/projects/myProjects`, {
      headers: {
        Authorization: auth.token,
      },
    })

    return await response.json()
  } catch (error) {
    throw error
  }
}

// Function for fetching a single project
export async function singleProject(projectId, token) {
  try {
    const response = await fetch(`${process.env.REACT_APP_SCRUM_BEE_API_URL}/projects/${projectId}`, {
      headers: {
        Authorization: token,
      },
    })

    return await response.json()
  } catch (error) {
    throw error
  }
}

// Function for fetching every user's projects
export async function getAllProjects(token) {
  try {
    const response = await fetch(`${process.env.REACT_APP_SCRUM_BEE_API_URL}/projects/`, {
      headers: {
        Authorization: token,
      },
    })

    return await response.json()
  } catch (error) {
    throw error
  }
}

// Function for changing the role of a specific user within a project
export async function changeRoleFromProject(token, projectId, payload) {
  try {
    const response = await axios
      .patch(
        `${process.env.REACT_APP_SCRUM_BEE_API_URL}/projects/role/${projectId}/${payload.userId}`,
        payload,
        { headers: { Authorization: token, "Content-type": "application/json", } }
      )

    if (response.status === 200) return response
    else throw new Error("Unable to update project member's role")
  } catch (error) {
    console.error(error)
    return error.response
  }
}

// Function for deleting one specific user from a project
export async function deleteUserFromProject(token, project, payload) {
  try {
    const response = await axios
      .delete(
        `${process.env.REACT_APP_SCRUM_BEE_API_URL}/projects/user/${project._id}/${payload.userId}`,
        {
          headers: { Authorization: token, "Content-type": "application/json" },
          data: { ownerId: project.ownerId, userRole: payload },
        }
      )

    if (response.status === 200) return response
    else throw new Error("Unable to delete the specified user from this project")
  } catch (error) {
    console.error(error)
    return error.response
  }
}

// Function for deleting one specific project
export async function deleteSpecificProject(token, projectId) {
  try {
    const response = await axios
      .delete(
        `${process.env.REACT_APP_SCRUM_BEE_API_URL}/projects/${projectId}`,
        { headers: { Authorization: token, "Content-type": "application/json", } }
      )

    if (response.status === 200) return response
    else throw new Error("Unable to delete the specified project")
  } catch (error) {
    console.error(error)
    return error.response
  }
}
