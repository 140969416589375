// External modules
import { Table, Spin } from "antd"
import { useEffect, useState } from "react"

// Bussiness logic
import { myIssues } from "../services/issues"
import SearchInDash from "./SearchInDash"

// User's data
import store from "../flux/store"

// CSS
import "@fontsource/poppins"

export default function MyIssues({ projectsMemebers }) {
  // Setting issues, loading and error hooks
  const [issues, setIssues] = useState()
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)

  // hook to set table parameters
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 5,
    },
  })

  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams({
      pagination,
      filters,
      ...sorter,
    })

    if (pagination.pageSize !== tableParams.pagination?.pageSize) setIssues([])
  }

  // Search issues
  const handleSearch = (childData) => setIssues(childData)

  // Fetchs every issue of the user
  useEffect(() => {
    // Fetchs every issue reported by user
    async function fetchIssues() {
      console.log()
      const fetchedData = await myIssues(store.getState().auth)

      if (fetchedData.status === "success") {
        // Successfull fetching
        setIssues(fetchedData.issues)
      } else {
        // Fetching error
        setError(true)
        console.error(fetchedData)
      }

      // Stopping the spinning
      setLoading(false)
    }

    if (store.getState().auth.user) fetchIssues()
  }, [setIssues])

  const columns = [
    {
      title: "Title",
      dataIndex: "title",
      // width: 200,
      sorter: (a, b) => a.title.localeCompare(b.title),
    },
    {
      title: "Description",
      dataIndex: "description",
      // width: 250,
    },
    {
      title: "Assignees",
      width: "auto",
      key: "assignees._id",
      filters: projectsMemebers
        ? projectsMemebers
        : [
            {
              text: (
                <>
                  Loading assignees...
                  <Spin style={{ position: "absolute", left: "10px" }} />
                </>
              ),
            },
          ],
      onFilter: (value, record) => {
        for (const assignee of record["assignees"]) if (assignee._id === value) return true
      },
      render: (payload) => {
        return (
          <ul>
            {payload.assignees.map((item) => {
              return (
                <li key={item._id}>
                  {item.firstname} {item.lastname}
                </li>
              )
            })}
          </ul>
        )
      },
    },
    {
      title: "Type",
      dataIndex: "type",
      // width: 150,
    },
    {
      title: "Priority",
      dataIndex: "priority",
      key: "priority",
      filters: [
        {
          text: "None",
          value: "toDefine",
        },
        {
          text: "Low",
          value: "normal",
        },
        {
          text: "Medium",
          value: "medium",
        },
        {
          text: "High",
          value: "high",
        },
      ],
      onFilter: (value, record) => record.priority.indexOf(value) === 0,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      filters: [
        {
          text: "Pending",
          value: "pending",
        },
        {
          text: "In progress",
          value: "inProgress",
        },
        {
          text: "Testing",
          value: "testing",
        },
        {
          text: "Done",
          value: "done",
        },
      ],
      onFilter: (value, record) => record.status.indexOf(value) === 0,
    },
    {
      title: "Created At",
      render: (payload) => {
        return new Date(payload.createdAt).toLocaleString()
      },
      // width: 175,
      sorter: (a, b) => a.createdAt > b.createdAt,
      defaultSortOrder: "descend",
    },
  ]

  if (loading)
    return (
      <div className="header">
        Loading issues... <Spin />
      </div>
    )

  // Error message
  if (!loading && error) return <p className="header">Error in fetching issues...</p>

  if (!loading && issues.length >= 0)
    return (
      <div>
        <p className="header">Issues reported by me</p>
        <SearchInDash handleSearch={handleSearch} />
        <Table
          dataSource={issues}
          columns={columns}
          rowKey="_id"
          pagination={tableParams.pagination}
          style={{
            marginTop: "40px",
            // maxWidth: "900px"
          }}
          onChange={handleTableChange}
        />
      </div>
    )
}
