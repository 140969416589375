// Images
import card from "./assets/img/card.png"
import card1 from "./assets/img/card1.png"
import card2 from "./assets/img/card2.png"
import card3 from "./assets/img/card3.png"
import card4 from "./assets/img/card4.png"
import card5 from "./assets/img/card5.png"
import card6 from "./assets/img/card6.png"
import card7 from "./assets/img/card7.png"
import card8 from "./assets/img/card8.png"

export default function getImage(image) {
  switch (image) {
    case "card.png":
      return card
    case "card1.png":
      return card1
    case "card2.png":
      return card2
    case "card3.png":
      return card3
    case "card4.png":
      return card4
    case "card5.png":
      return card5
    case "card6.png":
      return card6
    case "card7.png":
      return card7
    case "card8.png":
      return card8
    default:
      return null
  }
}
