import { Button, Card, Form, Input } from "antd"
import { useSearchParams } from "react-router-dom"
import { resetPassword } from "../services/users"

import "@fontsource/poppins"
import "../components/css/ResetPassword.css"

export default function ChangePassword() {
  const [searchParams] = useSearchParams()

  const onFinish = async (values) => {
    const response = await resetPassword(searchParams.get("token"), values.password)
    console.log(response)
    if (response.message === "jwt expired") return alert("Token exipred. Please send again a reset email request")
    if (response.status === "error") return alert(response.message)
    if (window.confirm(response.message) === true) {
      window.close()
      return window.open("https://www.hive-stag.scrumbee.app/")
    }
  }

  return (
    <Form
      labelWrap={true}
      labelAlign="right"
      labelCol={{ span: 6 }}
      requiredMark={false}
      size="large"
      className="passwordForm"
      onFinish={onFinish}
      onFinishFailed={() => {
        alert("Fill the form correctly")
      }}
      autoComplete="off"
    >
      <Card>
        <Form.Item
          label={<p>New Password</p>}
          name="password"
          rules={[
            {
              required: true,
              message: "Please input your new password!",
            },
            {
              message: "Type at least a 5 letter password",
              min: 5,
            },
          ]}
          hasFeedback
        >
          <Input.Password placeholder="Type your password here" />
        </Form.Item>

        <Form.Item
          label={<p>Confirm Password</p>}
          name="confirmPassword"
          rules={[
            {
              required: true,
              message: "Please confirm your password!",
            },
            {
              message: "Type at least a 5 letter password",
              min: 5,
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue("password") === value) return Promise.resolve()
                return Promise.reject("The two passwords don't match")
              },
            }),
          ]}
          hasFeedback
        >
          <Input.Password placeholder="Type again your password here to confirm" />
        </Form.Item>
      </Card>
      <Form.Item>
        <Button type="ghost" htmlType="submit" size="large">
          Submit
        </Button>
      </Form.Item>
    </Form>
  )
}
