// React components
import { useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { connect } from "react-redux"

// Query string library for redirect
import queryString from "query-string"

// Ant Design imports
import { Button, Alert, Form, Input } from "antd"

// Images imports
import userIMG from "../assets/ui/user.png"
import lockIMG from "../assets/ui/padlock.png"
import bee from "../assets/img/bee.png"

// Login import logics
import { login } from "../flux/actions/authActions"
import { clearErrors } from "../flux/actions/errorActions"
import "@fontsource/poppins"

// CSS
const formCSS = {
  maxWidth: "900px",
  margin: "auto",
}
const imgCSS = {
  height: "96px",
}
const loginCSS = {
  marginTop: "100px",
  marginBottom: "100px",
  textAlign: "center",
}
const buttonCSS = {
  border: "solid",
  borderWidth: "thin",
  borderRadius: "20px",
  width: "30%",
  background: "white",
  color: "black",
  fontSize: "12px",
}
const inputCSS = {
  padding: 7,
  borderRadius: "10px",
}

// Login form component
const TesterLogin = ({ user, error, login, clearErrors }) => {
  // navigation hooks
  const navigate = useNavigate()
  const location = useLocation()

  // Sets hook for email, password, loading state and possible errore messages
  const [email, setEmail] = useState("")
  const [password, setPassword] = useState("")
  const [msg, setMsg] = useState(null)
  const [loadings, setLoadings] = useState([])

  // Sets loading state for log in button preventing sending multiple submit
  const enterLoading = (index) => {
    setLoadings((prevLoadings) => {
      const newLoadings = [...prevLoadings]
      newLoadings[index] = true
      return newLoadings
    })
    setTimeout(() => {
      setLoadings((prevLoadings) => {
        const newLoadings = [...prevLoadings]
        newLoadings[index] = false
        return newLoadings
      })
    }, 6000)
  }

  // function to set email and password when they're inserted into the form
  const handleChangeEmail = (e) => setEmail(e.target.value)
  const handleChangePassword = (e) => setPassword(e.target.value)

  // function to handle when form fields are filled and submitted
  const handleOnSubmit = async (e) => {
    enterLoading(0)
    // Clears previous error, setting the msg to null and throwing clearErrors action that resets error state
    setMsg(null)
    clearErrors()

    e.preventDefault()

    const user = { email, password }

    // Attempt to login, using the action
    const { payload } = await login(user)

    const isAdmin = await (
      await fetch(`${process.env.REACT_APP_SCRUM_BEE_API_URL}/auth/isAdmin`, {
        headers: {
          Authorization: payload.token,
        },
        method: "GET",
      })
    ).json()

    // Redirects to homepage or admin page
    if (isAdmin) navigate("/admin/")
    else navigate("/homepage/")
  }

  useEffect(() => {
    // If the user is authenticated redirect them to the previous page
    if (user) {
      const { redirectTo } = queryString.parse(location.search)
      navigate(redirectTo == null ? "/homepage/" : redirectTo)
    }
  }, [user, navigate, location])

  useEffect(() => {
    // Check for login error and eventually sets the message to display it. Otherwise, it sets the msg to null.
    if (error.id === "LOGIN_FAIL") setMsg(error.msg.message)
    else setMsg(null)
  }, [error])

  return (
    <div style={{ fontFamily: "Poppins", textAlign: "center" }}>
      {msg ? <Alert message={msg} type="error" style={{ marginTop: "18px" }} /> : null}

      <Form
        initialValues={{
          remember: true,
        }}
        autoComplete="off"
        size="large"
        style={formCSS}
      >
        <div style={loginCSS}>
          <img src={bee} alt="Scrum Bee logo" style={imgCSS}></img>
          <p style={{ fontSize: "28px", fontWeight: 700 }}>Scrum Bee</p>
          <p style={{ fontSize: "18px", fontWeight: 700 }}>Tester</p>
        </div>
        <Form.Item
          rules={[
            {
              required: true,
              message: "Please input your email.",
            },
          ]}
        >
          <Input
            prefix={<img src={userIMG} alt="user"></img>}
            placeholder="Email"
            onChange={handleChangeEmail}
            style={inputCSS}
          />
        </Form.Item>
        <Form.Item
          rules={[
            {
              required: true,
              message: "Please input your password.",
            },
          ]}
        >
          <Input.Password
            prefix={<img src={lockIMG} alt="lock"></img>}
            placeholder="Password"
            onChange={handleChangePassword}
            style={inputCSS}
          />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" loading={loadings[0]} onClick={handleOnSubmit} style={buttonCSS}>
            Log in
          </Button>
        </Form.Item>
      </Form>
    </div>
  )
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  error: state.error,
})

export default connect(mapStateToProps, { login, clearErrors })(TesterLogin)
