export async function getAllUserstories(token) {
  try {
    const response = await fetch(`${process.env.REACT_APP_SCRUM_BEE_API_URL}/userStories/`, {
      headers: {
        Authorization: token,
      },
      method: "GET",
    })

    return response.json()
  } catch (error) {
    throw error
  }
}
