export async function getUser(token) {
  try {
    const response = await fetch(`${process.env.REACT_APP_SCRUM_BEE_API_URL}/users/me`, {
      headers: {
        Authorization: token,
      },
      method: "GET",
    })

    await response.json()
  } catch (error) {
    throw error
  }
}

export async function getUsers(token) {
  try {
    const response = await fetch(`${process.env.REACT_APP_SCRUM_BEE_API_URL}/users/`, {
      headers: {
        Authorization: token,
      },
      method: "GET",
    })

    return await response.json()
  } catch (error) {
    throw error
  }
}

export async function adminFilter(token, body) {
  try {
    const response = await fetch(`${process.env.REACT_APP_SCRUM_BEE_API_URL}/users/filter/`, {
      headers: {
        Authorization: token,
        "Content-type": "application/json",
      },
      body: JSON.stringify(body),
      method: "POST",
    })

    return await response.json()
  } catch (error) {
    throw error
  }
}

export async function changeRole(token, userId, body) {
  try {
    const response = await fetch(`${process.env.REACT_APP_SCRUM_BEE_API_URL}/users/type/${userId}`, {
      headers: {
        Authorization: token,
        "Content-type": "application/json",
      },
      body: JSON.stringify(body),
      method: "PUT",
    })

    return await response.json()
  } catch (error) {
    throw error
  }
}

export async function blockUnblockUser(token, userId) {
  try {
    const response = await fetch(`${process.env.REACT_APP_SCRUM_BEE_API_URL}/users/blockUnblock/${userId}`, {
      headers: {
        Authorization: token,
        "Content-type": "application/json",
      },
      method: "PUT",
    })

    return await response.json()
  } catch (error) {
    throw error
  }
}

export async function deleteUser(token, userId) {
  try {
    const response = await fetch(`${process.env.REACT_APP_SCRUM_BEE_API_URL}/users/${userId}`, {
      headers: {
        Authorization: token,
      },
      method: "DELETE",
    })

    await response.json()
  } catch (error) {
    throw error
  }
}

export async function resetPassword(token, password) {
  try {
    const response = await fetch(`${process.env.REACT_APP_SCRUM_BEE_API_URL}/users/changePw`, {
      headers: {
        Authorization: token,
        "Content-type": "application/json",
      },
      body: JSON.stringify({ newPassword: password }),
      method: "PUT",
    })
    return await response.json()
  } catch (error) {
    throw error
  }
}
