// React components
import { useEffect, useState } from "react"
import { useNavigate, useLocation } from "react-router-dom"
import { connect } from "react-redux"

// Ant Design component
import { Row, Col } from "antd"

// Custom components
import CreateIssue from "../components/CreateIssue"
import ProjectIssues from "../components/ProjectIssues"
import SingleProject from "../components/SingleProject"

// services
import store from "../flux/store"
import { singleProject } from "../services/projects"

function Project({ isAuthenticated }) {
  // navigation hooks
  const navigate = useNavigate()
  const prevLocation = useLocation()

  // function to redirect an authenticated user when page is refreshed
  useEffect(() => {
    if (!isAuthenticated) navigate(`/login/?redirectTo=${prevLocation.pathname}${prevLocation.search}`)
  }, [isAuthenticated, prevLocation, navigate])

  // Hooks to handle issues changes to display
  const [issues, setIssues] = useState([])

  // project hooks
  const projectId = window.location.search.toString().substring(1, window.location.search.toString().length)
  const [project, setProject] = useState(null)
  const [projectMembers, setMembers] = useState(null)

  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)

  // retrieves project and members
  useEffect(() => {
    async function fetchProject() {
      if (projectId) {
        const members = []

        const project = await singleProject(projectId, store.getState().auth.token)

        if (project.status === "success") {
          // Successfull fetching
          setProject(project.message)
          setLoading(false)
        } else {
          // Loading error
          setError(true)
          console.error(project.message)
        }

        const { role } = project.message

        for (const member of role) {
          if (member.role !== "tester")
            members.push({
              text: `${member.firstname} ${member.lastname}`,
              value: member.userId,
            })
        }

        setMembers(members)
      }
    }

    fetchProject()
  }, [projectId, setProject, setMembers, setLoading, setError])

  return (
    <Col>
      <Row>
        {/* Project infos */}
        <SingleProject project={project} loading={loading} error={error} />
      </Row>

      <Row>
        <p
          style={{
            fontSize: "28px",
            fontWeight: 700,
            color: "#BE0DCF",
            marginBottom: "10px",
          }}
        >
          Tester
        </p>
      </Row>
      {/* This is the button which opens the modal for issue creation */}
      <Row>{<CreateIssue issues={issues} setIssues={setIssues} />} </Row>
      <Row>
        {/* This is the list of the issues */}
        <ProjectIssues issues={issues} setIssues={setIssues} projectMembers={projectMembers} />
      </Row>
    </Col>
  )
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
})

export default connect(mapStateToProps)(Project)
