import { GET_ERRORS, CLEAR_ERRORS } from "./types"

// RETURN ERRORS
export const returnErrors = (msg, status, id = null) => {
  // dispatches the error action using given parameters as payload
  return {
    type: GET_ERRORS,
    payload: { msg, status, id },
  }
}

// CLEAR ERRORS
export const clearErrors = () => {
  // dispatches the error action which resets the error state
  return {
    type: CLEAR_ERRORS,
  }
}
