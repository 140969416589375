// External modules
import { useEffect } from "react"
import { useNavigate, Route, Routes } from "react-router-dom"

// Auth imports
import { Provider } from "react-redux"
import store from "./flux/store"
import { refreshToken } from "./flux/actions/authActions"

// Pages
import AppNavbar from "./components/AppNavbar"
import Dashboard from "./pages/Dashboard"
import Project from "./pages/Project"
import FriendInvitation from "./pages/FriendInvitation"
import ShareProject from "./pages/ShareProject"
import TesterLogin from "./pages/TesterLogin"
import EmailVerification from "./pages/EmailVerification"
import AdminPage from "./pages/AdminPage"
import ChangePassword from "./pages/ChangePassword"

// CSS
import "@fontsource/poppins"
import "./App.css"

const App = () => {
  // navigation hooks
  const navigate = useNavigate()

  // This hook is used each time a session is opened to reload previous session's token if not expired and also retrieves user id again
  useEffect(() => {
    if (store.getState().auth.refreshToken) store.dispatch(refreshToken())

    const isAdmin = async () => {
      if (
        await (
          await fetch(`${process.env.REACT_APP_SCRUM_BEE_API_URL}/auth/isAdmin`, {
            headers: {
              Authorization: store.getState().auth.token,
            },
            method: "GET",
          })
        ).json()
      )
        navigate("/admin/")
    }

    isAdmin()
  }, [navigate])

  return (
    <div className="dashboard">
      <Provider store={store}>
        <AppNavbar />
        <Routes>
          <Route path="/" element={<Dashboard />}></Route>
          <Route path="/homepage/*" element={<Dashboard />}></Route>
          <Route path="/login/*" element={<TesterLogin />}></Route>
          <Route path="/project/*" element={<Project />}></Route>
          <Route exact path="/friend/*" element={<FriendInvitation />}></Route>
          <Route exact path="/sharing/*" element={<ShareProject />}></Route>
          <Route exact path="/verification/*" element={<EmailVerification />}></Route>
          <Route exact path="/admin/*" element={<AdminPage />}></Route>
          <Route exact path="/password/*" element={<ChangePassword />}></Route>
        </Routes>
      </Provider>
    </div>
  )
}

export default App
