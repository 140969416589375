// Modules imports
import { Card, Button, Spin, Row, Col } from "antd"
import { Link } from "react-router-dom"

// Fetch data
import getImage from "../getImage"

// CSS and font
import "@fontsource/poppins"
import "./css/projectCard.css"

export default function MyProjects({ projects, loading, error }) {
  // Loading message
  if (loading)
    return (
      <div className="header">
        Loading projects... <Spin />
      </div>
    )

  // Error message
  if (!loading && error) return <p className="header">Error in fetching projects...</p>

  // No projects in the list
  if (!loading && projects.length === 0) return <p className="header">No projects, join one</p>

  //Project list
  if (!loading && projects.length > 0)
    return (
      <div>
        <p className="header" style={{ position: "relative" }}>
          Projects
        </p>
        <Row gutter={25} wrap={false} className="project-list">
          {projects.map((project) => (
            <Col key={project._id}>
              <Card
                bordered={false}
                style={{
                  height: 450,
                  width: 275,
                  backgroundImage: `url(${getImage(project.background)})`,
                }}
                size="small"
              >
                <ul>
                  <li>
                    <Card size="small" className="title-project">
                      {project.name}
                    </Card>
                  </li>
                  <li>
                    <Card size="small" className="type">
                      Type: <b>{project.type}</b>
                    </Card>
                  </li>
                  <li>
                    <Card size="small" className="description-project">
                      <b>Description: </b>
                      {project.description}
                    </Card>
                  </li>
                  <li>
                    {project.statistics !== undefined ? (
                      <Card size="small">
                        <ul
                          style={{
                            lineHeight: 1,
                          }}
                        >
                          <li>
                            <i>Members</i> - {project.statistics.members}
                          </li>
                          <li>
                            <i>Sprints</i> - {project.statistics.sprints}
                          </li>
                          <li>
                            <i>User Stories</i> - {project.statistics.userStories}
                          </li>
                        </ul>
                      </Card>
                    ) : null}
                  </li>
                  <li style={{ margin: "auto" }}>
                    <Link to={`/project/?${project._id}`}>
                      <Button type="ghost">Overview</Button>
                    </Link>
                  </li>
                </ul>
              </Card>
            </Col>
          ))}
        </Row>
      </div>
    )
}
