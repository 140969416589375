import { useSearchParams } from "react-router-dom"
import { Button } from "antd"

// CSS
import "@fontsource/poppins"
const buttonCSS = {
  width: "min-content",
  textAlign: "center",
}

export default function ShareProject() {
  const [searchParams] = useSearchParams()
  const status = searchParams.get("status")
  const message = searchParams.get("message")

  if (status === "success")
    return (
      <>
        <div style={{ fontFamily: "Poppins", marginTop: "60px" }}>
          <h3>{message}</h3>

          <Button
            type="ghost"
            style={buttonCSS}
            onClick={() => {
              window.open("https://www.hive-stag.scrumbee.app/")
            }}
            block
          >
            Go to Scrum-BEE
          </Button>
        </div>
      </>
    )

  if (status === "error")
    return (
      <>
        <div style={{ fontFamily: "Poppins", marginTop: "60px" }}>
          <p>{message}</p>
          <p>Something went wrong...</p>
        </div>
      </>
    )
}
