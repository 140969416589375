import React, { useState } from "react"
import { Button, Modal, Form, Input, Typography, Upload } from "antd"

import store from "../flux/store"
import { createIssue, projectIssues } from "../services/issues"

import "./css/CreateIssue.css"
const { Title } = Typography
const { TextArea } = Input

export default function CreateIssue({ issues, setIssues }) {
  // Check if modal is opened
  // If click button opens modal
  const [isModalOpen, setIsModalOpen] = useState(false) // Sets value to null on start and fullfills them on input
  const [filesToSend, setFilesToSend] = useState([])  // Set the array files to send
  const [form] = Form.useForm() // Creates the form

  const showModal = () => setIsModalOpen(true)

  const handleCreate = async () => {
    const projectId = window.location.search.toString().substring(1, window.location.search.toString().length)

    if (projectId !== "" && Object.keys(form.getFieldValue()).length !== 0) {
      setIsModalOpen(false)

      // CALLS POST ISSUE API
      await createIssue(
        {
          ...form.getFieldValue(),
          author: store.getState().auth.user,
          status: "pending",
          projectId,
        },
        filesToSend,
        store.getState().auth.token
      )

      // Updates issue array to display
      setIssues(
        (
          await projectIssues(
            window.location.search.toString().substring(1, window.location.search.toString().length),
            store.getState().auth.token
          )
        ).issues
      )
    }

    // resets the form and closes the modal
    form.resetFields()
  }

  const handleCancel = () => {
    form.resetFields()
    setIsModalOpen(false)
  }

  return (
    <>
      {/* This button opens modal */}
      <Button type="ghost" shape="round" className="newIssueButton" onClick={showModal}>
        New Issue
      </Button>
      <Modal
        title={<Title level={3}>Create issue</Title>}
        open={isModalOpen}
        mask={false}
        onCancel={handleCancel}
        footer={[
          <Button className="cancelNewIssueButton" key="Cancel" type="default" shape="round" onClick={handleCancel}>
            {"Cancel"}
          </Button>,
          <Button className="createNewIssueButton" key="Create" type="ghost" shape="round" onClick={handleCreate}>
            {"Create"}
          </Button>,
        ]}
      >
        <Form form={form}>
          <Form.Item
            name="title"
            label={<b>Title: </b>}
            rules={[
              {
                required: true,
              },
            ]}
            tooltip="Required"
            labelCol={{ span: 24 }}
            wrapperCol={{
              span: 24,
            }}
          >
            <TextArea
              size="middle"
              placeholder={"Add some title..."}
              autoSize={{
                minRows: 1,
                maxRows: 2,
              }}
            />
          </Form.Item>
          <Form.Item
            name="description"
            label={<b>Description: </b>}
            rules={[
              {
                required: true,
              },
            ]}
            tooltip="Required"
            labelCol={{ span: 24 }}
            wrapperCol={{
              span: 24,
            }}
          >
            <TextArea
              size="large"
              placeholder={"Add some description..."}
              autoSize={{
                minRows: 4,
                maxRows: 8,
              }}
            />
          </Form.Item>
          <Form.Item
            name="upload"
            label={<b>Upload file/s</b>}
            tooltip="Upload files if needed"
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24, }}
            valuePropName="fileList"
            getValueFromEvent={event => {
              setFilesToSend(() => {
                return event?.fileList.map(file => {
                  return file.originFileObj
                })
              })
            }}
          >
            <Upload.Dragger
              multiple
              listType="text"
              beforeUpload={() => { return false }}
              onRemove={file => {
                setFilesToSend(prevState => {
                  return prevState.filter(fl => {
                    return fl.uid !== file.uid
                  })
                })
              }}
            >
              <Button>Select File</Button>
            </Upload.Dragger>
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}
