import React, { useState, useEffect, useCallback } from "react"

import { projectIssues, createCommentForIssue, deleteCommentForIssue, updateCommentForIssue } from "../services/issues"
import store from "../flux/store"

import { Modal, Typography, Tooltip, List, Form, Input, Button, Space } from "antd"
import { Comment } from "@ant-design/compatible"
import "./css/CommentsModal.css"
const { Title } = Typography
const { TextArea } = Input

export default function CommentsModal(props) {
  // Hook to handle input for comment edit
  const [modifiedComment, setModifiedComment] = useState(null)

  // Hooks to handle elements to show/hide on edit/save buttons click
  const [showEditCommentBox, setShowEditCommentBox] = useState("hidden")
  const [showEditButton, setShowEditButton] = useState("visible")
  const [showSaveButton, setShowSaveButton] = useState("hidden")

  // Hook to handle new comment form
  const [form] = Form.useForm()

  // function to calculate time span between the date which a commented has been modified last time and now
  function handleTimeSpan(pastDate) {
    const difference = Math.round((new Date().getTime() - new Date(pastDate).getTime()) / (1000 * 3600 * 24))

    switch (difference) {
      case 0:
        return "Today"
      case 1:
        return "Yesterday"
      default:
        return `${difference} days ago`
    }
  }

  // function to togglie edit/save buttons for comments
  const toggleEditSaveButtons = useCallback(() => {
    if (showSaveButton === "hidden") {
      setShowSaveButton("visible")
      setShowEditButton("hidden")
    } else {
      setShowSaveButton("hidden")
      setShowEditButton("visible")
    }
  }, [showSaveButton])

  // function to handle when add comment button is pressed
  const handleAddComment = async (issueId) => {
    if (Object.keys(form.getFieldValue()).length !== 0) {
      const reqBody = {
        author: store.getState().auth.user,
        comment: form.getFieldsValue().newComment,
      }

      // CALLS PUT COMMENT ISSUE API
      const { comment: newComment } = await createCommentForIssue(reqBody, store.getState().auth.token, issueId)

      // Updates comments data to display comments
      props.issue.comments.push(newComment)

      // Re-fetches issues so comment displays
      props.setIssues(
        (
          await projectIssues(
            window.location.search.toString().substring(1, window.location.search.toString().length),
            store.getState().auth.token
          )
        ).issues
      )
    }

    // resets the form
    form.resetFields()
  }

  // function to handle when edit button is pressed
  const handleEditComment = useCallback(() => {
    // makes the edit form visible and toggles edit/save buttons
    setShowEditCommentBox("visible")
    toggleEditSaveButtons()
  }, [toggleEditSaveButtons])

  // function to handle when save button is pressed
  const handleSaveComment = useCallback(
    async (issue, commentId) => {
      if (modifiedComment) {
        const reqBody = {
          comment: modifiedComment,
        }

        // CALLS PUT ISSUE API
        const { comment } = await updateCommentForIssue(reqBody, store.getState().auth.token, issue._id, commentId)

        // Updates comments data to display comments
        props.issue.comments = issue.comments.map((commentObject) => {
          if (commentObject._id === commentId) commentObject = comment

          return commentObject
        })
      }

      // hides edit form, toggles edit/save buttons and resets the hook for the input given into edit form
      setShowEditCommentBox("hidden")
      toggleEditSaveButtons()
      setModifiedComment(null)
    },
    [modifiedComment, toggleEditSaveButtons, props]
  )

  // function to handle when delete button is pressed
  const handleDeleteComment = useCallback(
    async (issueId, commentId) => {
      // CALLS DELETES COMMENT ISSUE API
      await deleteCommentForIssue(store.getState().auth.token, issueId, commentId)

      // Updates comments data to display comments
      props.issue.comments = props.issue.comments.filter((comment) => comment._id !== commentId)

      // Re-fetches issues so comment displays
      props.setIssues(
        (
          await projectIssues(
            window.location.search.toString().substring(1, window.location.search.toString().length),
            store.getState().auth.token
          )
        ).issues
      )
    },
    [props]
  )

  // function to retrieve and build comments list
  const handleComments = useCallback(
    (issue) => {
      const comments = []
      if (issue.comments === undefined) return

      for (const commentObject of issue.comments)
        comments.push({
          actions: [
            <div>
              <Space>
                <Form layout="inline">
                  <Form.Item
                    className="editCommentBox"
                    name="editComment"
                    label=""
                    wrapperCol={{
                      span: 24,
                    }}
                    style={{ visibility: showEditCommentBox }}
                  >
                    <TextArea
                      size="middle"
                      placeholder={commentObject.comment}
                      autoSize={{
                        minRows: 1,
                        maxRows: 3,
                      }}
                      value={modifiedComment}
                      onChange={(e) => {
                        setModifiedComment(e.target.value)
                      }}
                    />
                  </Form.Item>
                </Form>
                <Button
                  className="editCommentButton"
                  type="ghost"
                  shape="round"
                  size="small"
                  style={{ visibility: showEditButton }}
                  onClick={() => {
                    handleEditComment()
                  }}
                >
                  {"Edit"}
                </Button>
                <Button
                  className="saveCommentButton"
                  type="ghost"
                  size="small"
                  shape="round"
                  style={{ visibility: showSaveButton }}
                  onClick={() => {
                    handleSaveComment(issue, commentObject._id)
                  }}
                >
                  {"Save"}
                </Button>
                <Button
                  className="deleteCommentButton"
                  type="ghost"
                  shape="round"
                  size="small"
                  onClick={() => {
                    handleDeleteComment(issue._id, commentObject._id)
                  }}
                >
                  {"Delete"}
                </Button>
              </Space>
            </div>,
          ],
          author: `${commentObject.author.firstname} ${commentObject.author.lastname}`,
          content: <p className="commentContent">{commentObject.comment}</p>,
          datetime: (
            <Tooltip title={new Date(commentObject.modifiedAt).toLocaleString()}>
              <span>{handleTimeSpan(commentObject.modifiedAt)}</span>
            </Tooltip>
          ),
        })

      return comments
    },
    [
      handleDeleteComment,
      handleEditComment,
      handleSaveComment,
      modifiedComment,
      showEditButton,
      showEditCommentBox,
      showSaveButton,
    ]
  )

  const [data, setData] = useState(handleComments(props.issue))

  useEffect(() => {
    setData(handleComments(props.issue))
  }, [handleComments, setData, props.issue])

  return (
    <div className="modal">
      <Modal
        title={<Title level={3}>Comments</Title>}
        open={props.isOpen}
        mask={false}
        onCancel={props.back}
        footer={[
          <Button className="backCommentsButton" key="Back" type="default" shape="round" onClick={props.back}>
            Back
          </Button>,
          <Button
            className="addCommentButton"
            key="AddComment"
            type="ghost"
            shape="round"
            onClick={() => {
              handleAddComment(props.issue._id)
            }}
          >
            Add comment
          </Button>,
        ]}
      >
        <p>
          {" "}
          <List
            className="comment-list"
            header={`${data.length} comments`}
            itemLayout="horizontal"
            dataSource={data}
            renderItem={(item) => (
              <li>
                <Comment
                  className="comment"
                  actions={
                    `${store.getState().auth.user.firstname} ${store.getState().auth.user.lastname}` === item.author
                      ? item.actions
                      : null
                  }
                  author={item.author}
                  content={item.content}
                  datetime={item.datetime}
                />
              </li>
            )}
          />
        </p>
        <Form form={form} layout="horizontal">
          <Form.Item
            className="newCommentBox"
            name="newComment"
            label=""
            wrapperCol={{
              span: 25,
            }}
          >
            <TextArea
              size="large"
              placeholder={"Add a new comment..."}
              autoSize={{
                minRows: 2,
                maxRows: 8,
              }}
            />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  )
}
