// React component
import { useEffect, useState } from "react"
import { useNavigate, useLocation } from "react-router-dom"
import { connect } from "react-redux"

// Ant Design component
import { Row } from "antd"

// Mu components
import MyIssues from "../components/MyIssues"
import MyProjects from "../components/MyProjects"

// Services
import store from "../flux/store"
import { myProjects } from "../services/projects"

function Dashboard({ isAuthenticated }) {
  // navigation hooks
  const navigate = useNavigate()
  const prevLocation = useLocation()

  // function to redirect an authenticated user when page is refreshed
  useEffect(() => {
    if (!isAuthenticated) navigate(`/login/?redirectTo=${prevLocation.pathname}${prevLocation.search}`)
  }, [isAuthenticated, prevLocation, navigate])

  // Projects retrival hooks
  const [projects, setProjects] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const [projectsMemebers, setMembers] = useState(null)

  // Retrives projects and members
  useEffect(() => {
    async function fetchProjects() {
      const fetchedProjects = await myProjects(store.getState().auth)

      if (fetchedProjects.status === "success")
        setProjects(fetchedProjects.user[0].myProjects.concat(fetchedProjects.user[0].SharedProjects))
      else {
        setError(true)
        console.error(fetchedProjects.message)
      }

      setLoading(false)
    }

    if (projects.length === 0) fetchProjects()

    const members = []
    for (const project of projects)
      for (const member of project.role)
        if (
          member.role !== "tester" &&
          !(function () {
            for (const el of members) if (member.userId === el.value) return true

            return false
          })()
        )
          members.push({
            text: `${member.firstname} ${member.lastname}`,
            value: member.userId,
          })

    setMembers(members)
  }, [projects, setProjects, setMembers, setLoading, setError])

  return (
    <Row justify={"space-around"}>
      <Row>
        <MyProjects projects={projects} loading={loading} error={error} />
      </Row>

      <Row>
        <MyIssues projectsMemebers={projectsMemebers} />
      </Row>
    </Row>
  )
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
})

export default connect(mapStateToProps)(Dashboard)
