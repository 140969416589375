import { legacy_createStore as createStore, applyMiddleware, compose } from "redux"
import thunk from "redux-thunk"
import rootReducer from "./reducers"

const initialState = {}

const middleWare = [thunk]

// Creates redux store
const store = createStore(rootReducer, initialState, compose(applyMiddleware(...middleWare)))

export default store
