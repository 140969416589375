// React component
import { useEffect, useState } from "react"
import { useNavigate, useLocation } from "react-router-dom"
import { connect } from "react-redux"

// Services and user info
import { getUsers, adminFilter, changeRole, blockUnblockUser, deleteUser } from "../services/users"
import { getAllIssues } from "../services/issues"
import { getAllUserstories } from "../services/userstories"
import { getAllTasks } from "../services/tasks"
import {
  getAllProjects,
  changeRoleFromProject,
  deleteUserFromProject,
  deleteSpecificProject
} from "../services/projects"
import store from "../flux/store"

import "../components/css/Admin.css"

// ANTD
import { Table, Spin, Form, Col, Space, Input, Button, Card, Typography, Modal, Select } from "antd"
import { FilterOutlined, RollbackOutlined, DeleteOutlined, SearchOutlined } from "@ant-design/icons"
const { Title } = Typography
const { TextArea } = Input

function AdminPage({ isAuthenticated }) {
  // navigation hooks
  const navigate = useNavigate()
  const prevLocation = useLocation()

  // function to redirect an authenticated user when page is refreshed
  useEffect(() => {
    if (!isAuthenticated) navigate(`/login/?redirectTo=${prevLocation.pathname}${prevLocation.search}`)
  }, [isAuthenticated, prevLocation, navigate])

  // hook to set table parameters
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 5,
    },
  })

  // hook to set project table parameters
  const [prjTableParams, setPrjTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 5,
    },
  })
  
  // hook to set project users table parameters
  const [prjUsersTableParams, setPrjUsersTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 5,
    },
  })

  // function to handle pagination
  const handleTableChange = (pagination, filters, sorter) => {
    /* set the correct pagination parameters based on the current
    opened tab in a specified moment of the time */

    // specific project tab opened
    if (showProject) {
      setPrjUsersTableParams({ pagination, filters, ...sorter, })
      if (pagination.pageSize !== prjUsersTableParams.pagination?.pageSize)
        setSpecProject(prevState => {
          return { ...prevState, role: [] }
        })
    }
    // specific project tab closed
    else {
      // project list tab
      if (view) {
        setPrjTableParams({ pagination, filters, ...sorter, })
        if (pagination.pageSize !== prjTableParams.pagination?.pageSize)
          setTotalProjects([])
      }
      // users list tab
      else {
        setTableParams({ pagination, filters, ...sorter, })
        if (pagination.pageSize !== tableParams.pagination?.pageSize)
          setUsers([])
      }
    }
  }

  /*                                    REACT STATES                                    */
  // hooks for users fetching
  const [users, setUsers] = useState(null)
  const [totalUsers, setTotalUsers] = useState(null)
  const [loading, setLoading] = useState(true)
  const [prjLoading, setPrjLoading] = useState(true)
  const [error, setError] = useState(false)
  const [isDeleteModalOpen, setDeleteModal] = useState(false) // delete modal hooks
  const [modalData, setModalData] = useState(false)
  const [tableKey, setTableKey] = useState(0)
  const [totalIssues, setTotalIssues] = useState(null)  // hooks to fetch total issues
  const [totalProjects, setTotalProjects] = useState(null)  // hooks to fetch total projects
  const [totalTasks, setTotalTasks] = useState(null)  // hooks to fetch total tasks
  const [totalUs, setTotalUs] = useState(null)  // hooks to fetch total userstories
  const [view, setView] = useState(false) // false: users - true: projects
  const [specProject, setSpecProject] = useState(null)  // specific project state
  const [showProject, setShowProject] = useState(false) // false: gen page - true: project page
  const [bakRole, setBakRole] = useState(null)  // stores the previous user's role if edit goes wrong

  /*                                    FETCHING FUNCTIONS                                    */
  // This is the fetch of the data to put in columns
  const fetchUsers = async () => {
    // Fetching data
    const fetchedData = await getUsers(store.getState().auth.token)
    if (fetchedData.status === "success") {
      // Successfull fetching
      setUsers(fetchedData.user)
      setTotalUsers(fetchedData.user.length)
    } else {
      // Fetching error
      setError(true)
      console.error(fetchedData)
    }
    // Stopping the spinning
    setLoading(false)
  }

  // This is the fetch for the existing issues data
  const retrieveTotalIssues = async () => {
    // CALLS API TO RETRIEVE ALL ISSUES IN THE PLATFORM
    const { issues } = await getAllIssues(store.getState().auth.token)
    // SHOW THE NUMBER OF ISSUES CONNECTED TO A PROJECT OR THE TOTAL ONE
    if (showProject) {
      const newItem = issues.filter(element => {
        if (element.projectId === specProject._id)
          return element
        else return false
      })
      setTotalIssues(newItem?.length)
    }
    else setTotalIssues(issues?.length)
  }

  // This is the fetch for the existing user stories data
  const retrieveTotalUserStories = async () => {
    // CALLS API TO RETRIEVE ALL USERSTORIES IN THE PLATFORM
    const { userStory } = await getAllUserstories(store.getState().auth.token)
    // SHOW THE NUMBER OF USER STORIES CONNECTED TO A PROJECT OR THE TOTAL ONE
    if (showProject) {
      const newItem = userStory.filter(element => {
        if (element.projectId === specProject._id)
          return element
        else return false
      })
      setTotalUs(newItem?.length)
    }
    else setTotalUs(userStory?.length)
  }

  // This is the fetch for the existing tasks data
  const retrieveTotalTasks = async () => {
    // CALLS API TO RETRIEVE TOTAL TASKS IN THE PLATFORM
    const response = await getAllTasks()

    if (response.status === 200) {
      // SHOW THE NUMBER OF TASKS CONNECTED TO A PROJECT OR THE TOTAL ONE
      if (showProject) {
        const newItem = response.data.data.filter(element => {
          if (element.projectId === specProject._id)
            return element
          else return false
        })
        setTotalTasks(newItem?.length)
      }
      else setTotalTasks(response.data.data?.length)
    }
    else setTotalTasks(0)  // 0 if error status
  }

  // function to get all projects from db
  const retrieveTotalProjects = async () => {
    // CALLS API TO RETRIEVE TOTAL PROJECTS IN THE PLATFORM
    const fetchedData = await getAllProjects(store.getState().auth.token)
    if (fetchedData.status === "success") {
      // Successfull fetching
      setTotalProjects(fetchedData.projects)
    }
    else {
      // Fetching error
      setError(true)
      console.error(fetchedData)
    }
    // Stopping the spinning
    setPrjLoading(false)
  }

  /*                                    REACT USEEFFECT                                    */
  // fetching users
  useEffect(() => {
    fetchUsers()
  }, [setUsers, setTotalUsers, showProject])   // [setUsers, setTotalUsers])

  // fetching total issues
  useEffect(() => {
    retrieveTotalIssues()
  }, [setTotalIssues, showProject])   // [setTotalIssues])

  // fetching total userstories
  useEffect(() => {
    retrieveTotalUserStories()
  }, [setTotalUs, showProject])   // [setTotalUs])

  // fetching total tasks
  useEffect(() => {
    retrieveTotalTasks()
  }, [setTotalTasks, showProject])   // [setTotalTasks])

  // fetching total projects
  useEffect(() => {
    retrieveTotalProjects()
  }, [setTotalProjects, showProject])   // [setTotalProjects])

  /* select entries for project type (use "other" as
  value to perform search outside standard parameters) */
  const projectTypeEntries = [
    { value: "scrum", name: "Scrum" },
    { value: "kanban", name: "Kanban" },
    { value: "other", name: "Other" }
  ]

  // select entries for user type
  const userTypeEntries = [
    { value: "default", name: "Default" },
    { value: "voiceOffice", name: "Voice Office" }
  ]

  // select entries for user role
  const userRoleEntries = [
    { value: "pOwner", name: "pOwner" },
    { value: "master", name: "Master" },
    { value: "team", name: "Team" },
    { value: "tester", name: "Tester" }
  ]

  const [form] = Form.useForm()

  // function to block or unblock a user
  const handleBlockUser = async (userId) => {
    // CALLS API TO BLOCK/UNBLOCK A USER
    const { user } = await blockUnblockUser(store.getState().auth.token, userId)

    if (user.isVerified) window.alert("User unblocked successfully")
    else window.alert("User blocked successfully")

    if (Object.keys(form.getFieldValue()).length !== 0)
      setUsers((await adminFilter(store.getState().auth.token, form.getFieldValue())).users)
    else setUsers((await getUsers(store.getState().auth.token)).user)
  }

  // changes style of block/unblock buttons
  const switchButtonStyle = (isVerified) => (isVerified ? "blockButton" : "unblockButton")

  // function to change a user's type
  const handleChangeType = async (userId, type) => {
    // CALLS API TO CHANGE USER'S ROLE TO VO OR BACK TO DEFAULT
    if (type === "default") await changeRole(store.getState().auth.token, userId, { type: "voiceOffice" })
    else await changeRole(store.getState().auth.token, userId, { type: "default" })

    if (type === "default") window.alert("Role changed to Voice Office")
    else window.alert("Role changed to default")

    if (Object.keys(form.getFieldValue()).length !== 0)
      setUsers((await adminFilter(store.getState().auth.token, form.getFieldValue())).users)
    else setUsers((await getUsers(store.getState().auth.token)).user)
  }

  // function to delete a user
  const handleDelete = async (userId) => {
    // CALLS API TO DELETE USER
    await deleteUser(store.getState().auth.token, userId)

    // close modal
    setDeleteModal(false)

    // reset table
    let users
    if (Object.keys(form.getFieldValue()).length !== 0)
      users = (await adminFilter(store.getState().auth.token, form.getFieldValue())).users
    else users = (await getUsers(store.getState().auth.token)).user

    setUsers(users)
    setTotalUsers(users.length)

    // allert
    window.alert("User deleted")
  }

  async function deleteProject(payload) {
    // delete the selected project from db
    const response = await deleteSpecificProject(store.getState().auth.token, payload._id)

    // close modal
    setDeleteModal(false)

    if (response.status === 200) {
      await retrieveTotalProjects()   // update the list of total projects
      window.alert(`Project "${payload.name}" succesfully deleted`)
    }
    else window.alert(`Unable to delete project "${payload.name}"`)
  }

  async function handleDeleteFromProject(payload) {
    // delete the selected user from the current project
    const response = await deleteUserFromProject(store.getState().auth.token, specProject, payload)

    // close modal window
    setDeleteModal(false)

    // alert
    if (response.status === 200) {
      // remove the same user from the selected project state
      setSpecProject(prevState => {
        return {
          ...prevState,
          role: prevState.role.filter(element => {
            if (element.userId !== payload.userId)
              return element
            else return false
          })
        }
      })
      window.alert(`${payload.firstname} ${payload.lastname} succesfully removed from this project`)
    }
    else window.alert(`Unable to remove ${payload.firstname} ${payload.lastname} from this project`)
  }

  // users table data
  const columns = [
    {
      title: "Name",
      key: "name",
      render: (payload) => {
        return `${payload.firstname} ${payload.lastname}`
      },
    },
    {
      title: "Email",
      key: "email",
      render: (payload) => {
        return `${payload.email}`
      },
    },
    {
      title: "Voice Office",
      key: "type",
      render: (payload) => {
        return payload.type === 'voiceOffice' ? 'si' : 'no'
      },
    },
    {
      title: "Phone Number",
      key: "phoneNumber",
      render: (payload) => {
        return payload.phoneNumber ? payload.phoneNumber : "none"
      },
    },
    {
      title: "Verified",
      key: "verified",
      filterMultiple: false,
      filters: [
        { text: "active", value: true },
        { text: "inactive", value: false },
      ],
      onFilter: (value, record) => record.isVerified === value,
      render: (payload) => {
        return payload.isVerified === true ? "active" : "inactive"
      },
    },
    {
      title: "Projects",
      key: "projects",
      render: (payload) => {
        return payload.myProjects.concat(payload.SharedProjects).length
      },
    },
    {
      title: "Actions",
      key: "action",
      render: (payload) => {
        return (
          <div>
            <Space>
              <Button
                key="block"
                type="ghost"
                shape="round"
                className={switchButtonStyle(payload.isVerified)}
                onClick={() => {
                  handleBlockUser(payload._id)
                }}
              >
                {payload.isVerified ? "Block" : "Unblock"}
              </Button>
              <Button
                key="changeType"
                type="ghost"
                shape="round"
                onClick={() => {
                  handleChangeType(payload._id, payload.type)
                }}
              >
                {payload.type === "default" ? "Voice Office" : "Default"}
              </Button>
              <Button
                key="delete"
                type="ghost"
                shape="round"
                className="adminDeleteButton"
                size="small"
                onClick={() => {
                  setDeleteModal(true)
                  setModalData(payload)
                }}
              >
                <DeleteOutlined className="adminDeleteIcon" />
              </Button>
              <Modal
                open={isDeleteModalOpen}
                mask={false}
                onCancel={() => setDeleteModal(false)}
                footer={[
                  <Button
                    key="deleteNo"
                    type="ghost"
                    shape="round"
                    onClick={() => {
                      setDeleteModal(false)
                    }}
                  >
                    No
                  </Button>,
                  <Button
                    key="deleteYes"
                    type="ghost"
                    shape="round"
                    onClick={() => {
                      handleDelete(modalData._id)
                    }}
                  >
                    Yes
                  </Button>,
                ]}
              >
                {
                  <b>
                    Will you delete {modalData.firstname} {modalData.lastname}?
                  </b>
                }
              </Modal>
            </Space>
          </div>
        )
      },
    },
  ]

  // open or close the specific project tab
  async function showSpecificProject(payload, action) {
    if (!action) await retrieveTotalProjects()  // refresh the projects list when tab is closed
    else {
      setPrjUsersTableParams({ pagination: { current: 1, pageSize: 5, }}) // reset pagination parameters
      if (payload.role?.length > 0) {
        /* to prevent unique key warning if userId is not present, assign a
        temporary Id that will be removed before the update CRUD operation */
        payload.role.forEach((user, index) => {
          user.renderId = index
        })
      }
    }

    setSpecProject(payload)   // set null or the specific project object
    setShowProject(action)    // decide to show the specific project tab or not
  }

  // search filter within the table
  function searchFromTable(title, dataIndex, key, placeholder) {
    return ({
      title: title,
      key: key,
      filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters}) => {
        return (
          <div style={{ display: "flex" }}>
            <Input
              autoFocus
              placeholder={placeholder}
              value={selectedKeys[0]}
              onChange={event => {
                const { value } = event.target
                setSelectedKeys(value ? [value] : [])
                confirm({ closeDropdown: false })
              }}
              onPressEnter={() => { confirm({ closeDropdown: false }) }}
              onBlur={() => { confirm({ closeDropdown: false }) }}
            />
            <Button
              onClick={() => {
                clearFilters()
                confirm({ closeDropdown: false })
              }}
              style={{ backgroundColor: "red", color: "white" }}
            >Reset</Button>
          </div>
        )
      },
      filterIcon: () => { return <SearchOutlined/> },
      onFilter: (value, record) => {
        if (record[dataIndex] !== undefined)
          return record[dataIndex].toLowerCase().trim().includes(value.toLowerCase())
      },
    })
  }

  // select filter within the table
  function selectFromTable(title, dataIndex, key, array) {
    return ({
      title: title,
      key: key,
      filterMultiple: false,
      filters: array.map(role => {
        return ({ text: role.name, value: role.value })
      }),
      onFilter: (value, record) => {
        if (record[dataIndex] !== undefined) {
          switch (value) {
            case "other": {
              let passed = 0
              const entries = array.filter(el => {
                if (!el.value.toLowerCase().trim().includes(value.toLowerCase()))
                  return el
                else return false
              })
              entries.forEach(el => {
                if (!record[dataIndex].toLowerCase().trim().includes(el.value.toLowerCase()))
                  passed += 1
              })
              if (passed === entries?.length) return record[dataIndex].trim()
              break
            }
            default: return record[dataIndex].toLowerCase().trim().includes(value.toLowerCase())
          }
        }
      },
    })
  }

  // projects table data
  const projectsColumns = [
    { ...searchFromTable("Name", "name", "project-name", "Find by Project Name..."),
      render: (payload) => {
        return payload.name
      },
    },
    {
      title: "Description",
      key: "project-description",
      render: (payload) => {
        return payload.description
      },
    },
    { ...selectFromTable("Type", "type", "project-type", projectTypeEntries),
      render: (payload) => {
        return payload.type
      },
    },
    {
      title: "Actions",
      key: "project-actions",
      render: (payload) => {
        return (
          <div>
            <Space>
              <Button
                key="viewProject"
                type="ghost"
                shape="round"
                onClick={() => showSpecificProject(payload, true)}
              >Open</Button>
              <Button
                key="deleteProject"
                type="ghost"
                shape="round"
                className="adminDeleteButton"
                size="small"
                onClick={() => {
                  setDeleteModal(true)
                  setModalData(payload)
                }}
              >
                <DeleteOutlined className="adminDeleteIcon" />
              </Button>
              <Modal
                open={isDeleteModalOpen}
                mask={false}
                onCancel={() => setDeleteModal(false)}
                footer={[
                  <Button
                    key="project-deleteNo"
                    type="ghost"
                    shape="round"
                    onClick={() => {
                      setDeleteModal(false)
                    }}
                  >No</Button>,
                  <Button
                    key="project-deleteYes"
                    type="ghost"
                    shape="round"
                    onClick={() => deleteProject(modalData)}
                  >Yes</Button>,
                ]}
              >
                {
                  <b>
                    Will you delete {modalData.name}?
                  </b>
                }
              </Modal>
            </Space>
          </div>
        )
      },
    }
  ]

  // update the role of a specific user connected to a project
  function updateUserListener(event, payload) {
    setBakRole(payload.role)
    setSpecProject(prevState => {
      let newState = { ...prevState }
      newState.role.forEach(e => {
        if (e.userId === payload.userId)
          e.role = event
      })
      return newState
    })
  }

  async function updateUserProjectRole(payload) {
    // remove the renderId from the payload before patch CRUD operation
    if (payload.hasOwnProperty("renderId")) delete payload.renderId

    // update the role of one specific user within a project
    const response = await changeRoleFromProject(store.getState().auth.token, specProject._id, payload)

    // alert
    if (response.status === 200) {
      // assign renderId
      if (response.data.role?.length > 0) {
        response.data.role.forEach((user, index) => {
          user.renderId = index
        })
      }
      setSpecProject(response.data)   // update the specific project state
      if (payload.role === "pOwner")
        window.alert(`${payload.firstname} ${payload.lastname}'s role requested to be changed to '${payload.role}'\nCheck your email`)
      else window.alert(`${payload.firstname} ${payload.lastname}'s role changed to '${payload.role}'`)
    }
    else {
      setSpecProject(prevState => {
        let newState = { ...prevState }
        newState.role.forEach((user, index) => {
          if (user.userId === payload.userId) {
            user.renderId = index
            user.role = bakRole
          }
        })
        return newState
      })
      window.alert(`Unable to update role for ${payload.firstname} ${payload.lastname}`)
    }
  }

  // project table users data
  const projectUsersColumns = [
    { ...searchFromTable("Name", "lastname", "roles-user", "Find by Lastame..."),
      render: (payload) => {
        return `${payload.firstname} ${payload.lastname}`
      },
    },
    { ...searchFromTable("Email", "email", "roles-email", "Find by Email..."),
      render: (payload) => {
        return payload.email
      },
    },
    { ...selectFromTable("Role", "role", "roles-role", userRoleEntries),
      render: (payload) => {
        return (
          <Select
            value={payload.role}
            onChange={event => updateUserListener(event, payload)}
            style={{ width: "100px" }}
          >
            { userRoleEntries.map((element, index) => {
                return (
                  <Select.Option
                    key={index}
                    value={element.value}
                  >
                    {element.name}
                  </Select.Option>
                )
              })
            }
          </Select>
        )
      },
    },
    {
      title: "Actions",
      key: "roles-actions",
      render: (payload) => {
        return (
          <div>
            <Space>
              <Button
                key="roles-edit"
                type="ghost"
                shape="round"
                onClick={() => {
                  updateUserProjectRole(payload)
                }}
              >Update</Button>
              <Button
                key="roles-delete"
                type="ghost"
                shape="round"
                className="adminDeleteButton"
                size="small"
                onClick={() => {
                  setDeleteModal(true)
                  setModalData(payload)
                }}
              >
                <DeleteOutlined className="adminDeleteIcon" />
              </Button>
              <Modal
                open={isDeleteModalOpen}
                mask={false}
                onCancel={() => setDeleteModal(false)}
                footer={[
                  <Button
                    key="roles-deleteNo"
                    type="ghost"
                    shape="round"
                    onClick={() => {
                      setDeleteModal(false)
                    }}
                  >No</Button>,
                  <Button
                    key="roles-deleteYes"
                    type="ghost"
                    shape="round"
                    onClick={() => handleDeleteFromProject(modalData)}
                  >Yes</Button>,
                ]}
              >
                {
                  <b>
                    Will you delete {modalData.firstname} {modalData.lastname} from this project?
                  </b>
                }
              </Modal>
            </Space>
          </div>
        )
      },
    },
  ]

  // function to set the view (users or projects)
  async function viewSelection(action) {
    // update the list of projects or users, based on the current view
    if (action) {
      setPrjLoading(true)   // set the projects loading spinning to true
      setPrjTableParams({ pagination: { current: 1, pageSize: 5, }}) // reset pagination parameters
      await retrieveTotalProjects()   // refresh the projects list
    }
    else {
      setLoading(true)    // set the users loading spinning to true
      setTableParams({ pagination: { current: 1, pageSize: 5, }})  // reset pagination parameters
      await fetchUsers()  // refresh the users list
    }

    setView(action)   // set the current view that needs to be shown
  }

  // function to handle filter with forms
  const handleFilter = async (event) => {
    event.preventDefault()
    /* remove those attribute within the form array where their value
    equals to empty string, preventing the user to perform a clean search */
    for (const element in form.getFieldValue()) {
      if (form.getFieldValue()[element] === "" ||
      form.getFieldValue()[element] === undefined)
        delete form.getFieldValue()[element]  // delete the empty attribute
    }

    if (Object.keys(form.getFieldValue()).length !== 0) {
      // CALLS ADMIN FILTER API
      const { users } = await adminFilter(store.getState().auth.token, form.getFieldValue())

      // Updates table
      setUsers(users)
    }
  }

  const handleReset = async (event) => {
    event.preventDefault()
    // resets the table
    setUsers((await getUsers(store.getState().auth.token)).user)
    setTableKey((tableKey) => tableKey + 1)

    // resets the form
    form.resetFields()
  }

  if (loading)
    return (
      <div className="header">
        Loading users... <Spin />
      </div>
    )

  if (prjLoading)
    return (
      <div className="header">
        Loading projects... <Spin />
      </div>
    )

  // user search form function
  function userSearchForm() {
    return (
      <Form form={form} layout="horizontal">
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            alignItems: "self-end",
            marginTop: "40px"
          }}
        >
          <div style={{ display: "flex" }}>
            <Form.Item
              label={<b>Email </b>}
              name="email"
              labelCol={{ span: 7 }}
              wrapperCol={{ span: 20 }}
            >
              <TextArea
                placeholder={"Input email..."}
                autoSize={{ minRows: 1, maxRows: 1 }}
              />
            </Form.Item>
            <Form.Item
              label={<b>Lastname </b>}
              name="lastname"
              labelCol={{ span: 7 }}
              wrapperCol={{ span: 20 }}
            >
              <TextArea
                placeholder={"Input lastname..."}
                autoSize={{ minRows: 1, maxRows: 1 }}
              />
            </Form.Item>
            <Form.Item
              label={<b>{showProject ? "Role" : "Type"} </b>}
              name={showProject ? "role" : "type"}
              labelCol={{ span: 7 }}
              wrapperCol={{ span: 20 }}
            >
              <Select
                style={{ width: "120px" }}
                placeholder={`Set ${showProject ? "Role" : "Type"}...`}
              >
                { userTypeEntries.map((element, index) => {
                    return (
                      <Select.Option
                        key={index}
                        value={element.value}
                      >
                        {element.name}
                      </Select.Option>
                    )
                  })
                }
              </Select>
            </Form.Item>
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Form.Item
              labelCol={{ span: 7 }}
              wrapperCol={{ span: 20 }}
            >
              <Button
                key="filter"
                type="ghost"
                shape="round"
                onClick={event => handleFilter(event)}
              >
                Filter <FilterOutlined style={{ verticalAlign: "1px" }} />
              </Button>
            </Form.Item>
            <Form.Item
              labelCol={{ span: 7 }}
              wrapperCol={{ span: 20 }}
            >
              <Button
                key="resetFilter"
                type="ghost"
                shape="round"
                onClick={event => handleReset(event)}
              >
                Reset <RollbackOutlined style={{ verticalAlign: "1px" }} />
              </Button>
            </Form.Item>
          </div>
        </div>
      </Form>
    )
  }
    
  // Error message
  if (!loading && error) return <p className="header">Error in fetching users...</p>

  // Error message (projects)
  if (!prjLoading && error) return <p className="header">Error in fetching projects...</p>

  if (!loading || !prjLoading)
    return (
      <Col>
        {
          showProject
            ?
              <div
                style={{ display:"flex", flexDirection:"column" }}
              >
                <div style={{ marginBottom: "20px" }}>
                  <Space>
                    <Button
                      key="closeProject"
                      type="ghost"
                      shape="round"
                      size="large"
                      onClick={() => showSpecificProject(null, false)}
                    >Back</Button>
                  </Space>
                </div>
                <div style={{ marginBottom: "20px" }}>
                  <Space>
                    <label
                      className="tester"
                      style={{ fontSize: "24px", fontWeight: "bold" }}
                    >
                      {specProject?.name}
                    </label>
                    <label
                      style={
                        {
                          display: "flex",
                          width: "70px",
                          height: "20px",
                          fontSize: "12px",
                          fontWeight: "bold",
                          borderRadius: "10px",
                          backgroundColor: "rgb(200, 0, 200)",
                          color: "white",
                          justifyContent: "center",
                          alignItems: "center"
                        }
                      }
                    >{specProject?.type}</label>
                  </Space>
                </div>
              </div>
            :
              <div
                style={{ marginBottom: "20px" }}
              >
                <Space>
                  <Button
                    key="userList"
                    type="ghost"
                    shape="round"
                    size="large"
                    onClick={() => viewSelection(false)}
                  >Users</Button>
                  <Button
                    key="projects"
                    type="ghost"
                    shape="round"
                    size="large"
                    onClick={() => viewSelection(true)}
                  >Projects</Button>
                </Space>
              </div>
        }
        <Space>
          <Card
            style={{
              height: 90,
              width: 220,
              backgroundColor: "white",
              borderColor: "gray",
            }}
          >
            <Title level={4} style={{ textAlign: "center", marginTop: "-10px" }}>
              Users <br></br>
              <div style={{ marginTop: "5px" }}>
                {!showProject ? totalUsers : specProject.role?.length}
              </div>
            </Title>
          </Card>
          <Card
            style={{
              height: 90,
              width: 220,
              backgroundColor: "white",
              borderColor: "gray",
            }}
          >
            <Title level={4} style={{ textAlign: "center", marginTop: "-10px" }}>
              User Stories <br></br>
              <div style={{ marginTop: "5px" }}>{totalUs}</div>
            </Title>
          </Card>
          <Card
            style={{
              height: 90,
              width: 220,
              backgroundColor: "white",
              borderColor: "gray",
            }}
          >
            <Title level={4} style={{ textAlign: "center", marginTop: "-10px" }}>
              Tasks <br></br>
              <div style={{ marginTop: "5px" }}>{totalTasks}</div>
            </Title>
          </Card>
          <Card
            style={{
              height: 90,
              width: 220,
              backgroundColor: "white",
              borderColor: "gray",
            }}
          >
            <Title level={4} style={{ textAlign: "center", marginTop: "-10px" }}>
              Issues <br></br>
              <div style={{ marginTop: "5px" }}>{totalIssues}</div>
            </Title>
          </Card>
          {
            !showProject &&
              <Card
                style={{
                  height: 90,
                  width: 220,
                  backgroundColor: "white",
                  borderColor: "gray",
                }}
              >
                <Title level={4} style={{ textAlign: "center", marginTop: "-10px" }}>
                  Projects <br></br>
                  <div style={{ marginTop: "5px" }}>{totalProjects?.length}</div>
                </Title>
              </Card>
          }
        </Space>
        {
          showProject
            ?
              <div>
                <br></br><label
                  className="projectUserListLabel"
                  style={{ fontSize: "24px", fontWeight: "bold", color: "rgb(200, 0, 200)" }}
                >Project Users</label>
                <Table
                  style={{ marginTop: "25px" }}
                  dataSource={specProject.role}
                  columns={projectUsersColumns}
                  key={tableKey}
                  rowKey="renderId"
                  size="small"
                  pagination={prjUsersTableParams.pagination}
                  onChange={handleTableChange}
                />
              </div>
            :
              view
                ? (totalProjects && totalProjects.length >= 0) &&
                  <div>
                    <br></br><label
                      className="projectListLabel"
                      style={{ fontSize: "24px", fontWeight: "bold", color: "rgb(200, 0, 200)" }}
                    >All Projects</label>
                    <Table
                      style={{ marginTop: "25px" }}
                      dataSource={totalProjects}
                      columns={projectsColumns}
                      key={tableKey}
                      rowKey="_id"
                      size="small"
                      pagination={prjTableParams.pagination}
                      onChange={handleTableChange}
                    />
                  </div>
                : (users && users.length >= 0) &&
                  <div>
                    { userSearchForm() }
                    <Space>
                      <label
                        className="userListLabel"
                        style={{ fontSize: "24px", fontWeight: "bold", color: "rgb(200, 0, 200)" }}
                      >All Users</label>
                    </Space>
                    <Table
                      style={{ marginTop: "25px" }}
                      dataSource={users}
                      columns={columns}
                      key={tableKey}
                      rowKey="_id"
                      size="small"
                      pagination={tableParams.pagination}
                      onChange={handleTableChange}
                    />
                  </div>
        }
      </Col>
    )
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
})

export default connect(mapStateToProps)(AdminPage)
