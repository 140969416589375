import axios from 'axios'

// Function for fetching my issues
export async function myIssues(auth) {
  try {
    const response = await fetch(`${process.env.REACT_APP_SCRUM_BEE_API_URL}/api/issues/user/${auth.user._id}`, {
      headers: {
        Authorization: auth.token,
      },
      method: "GET",
    })

    return response.json()
  } catch (error) {
    throw error
  }
}

// Function for fetching issues from a given project
export async function projectIssues(projectId, token) {
  try {
    const response = await fetch(`${process.env.REACT_APP_SCRUM_BEE_API_URL}/api/issues/project/${projectId}`, {
      headers: {
        Authorization: token,
      },
      method: "GET",
    })

    return await response.json()
  } catch (error) {
    throw error
  }
}

// Function to update a issue
export async function updateIssue(body, token, issueId) {
  try {
    const response = await fetch(`${process.env.REACT_APP_SCRUM_BEE_API_URL}/api/issues/${issueId}`, {
      headers: {
        Authorization: token,
        "Content-type": "application/json",
      },
      body: JSON.stringify(body),
      method: "PUT",
    })
    return await response.json()
  } catch (error) {
    throw error
  }
}

// Function to delete a issue
export async function deleteIssue(token, issueId) {
  try {
    const response = await fetch(`${process.env.REACT_APP_SCRUM_BEE_API_URL}/api/issues/${issueId}`, {
      headers: {
        Authorization: token,
        "Content-type": "application/json",
      },
      method: "DELETE",
    })
    return await response.json()
  } catch (error) {
    throw error
  }
}

// Function to create a comment
export async function createCommentForIssue(body, token, issueId) {
  try {
    const response = await fetch(`${process.env.REACT_APP_SCRUM_BEE_API_URL}/api/issues/${issueId}/comments`, {
      headers: {
        Authorization: token,
        "Content-type": "application/json",
      },
      body: JSON.stringify(body),
      method: "PUT",
    })
    return await response.json()
  } catch (error) {
    throw error
  }
}

// Function to update a comment
export async function updateCommentForIssue(body, token, issueId, commentId) {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_SCRUM_BEE_API_URL}/api/issues/${issueId}/comments/${commentId}`,
      {
        headers: {
          Authorization: token,
          "Content-type": "application/json",
        },
        body: JSON.stringify(body),
        method: "PUT",
      }
    )
    return await response.json()
  } catch (error) {
    throw error
  }
}

// Function to delete a comment
export async function deleteCommentForIssue(token, issueId, commentId) {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_SCRUM_BEE_API_URL}/api/issues/${issueId}/comments/${commentId}`,
      {
        headers: {
          Authorization: token,
          "Content-type": "application/json",
        },
        method: "DELETE",
      }
    )
    return await response.json()
  } catch (error) {
    throw error
  }
}

// Function to create an issue
export async function createIssue(body, filesToSend, token) {
  try {
    const objToSend = { issue: JSON.stringify(body) }
    if (filesToSend?.length > 0) objToSend.files = [ ...filesToSend ]

    const response = await axios.post(
      `${process.env.REACT_APP_SCRUM_BEE_API_URL}/api/issues`,
      objToSend,
      {
        headers: {
          Authorization: token,
          "Content-type": "multipart/form-data",
        }
      }
    )

    if (response.status === 200) {
      window.alert(`New Issue "${response.data.issue.title}" successful registered`)
    }
    return response
  } catch (error) {
    window.alert(`Unable to post new issue...`)
    throw error
  }
}

// Function to filter an issue using a word
export async function searchIssues(token, { word, projectId }) {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_SCRUM_BEE_API_URL}/api/issues/reactProject/search/?word=${word}&projectId=${projectId}`,
      {
        headers: {
          Authorization: token,
          "Content-type": "application/json",
        },
        method: "GET",
      }
    )
    return await response.json()
  } catch (error) {
    throw error
  }
}

// Function to filter an issue into tester page dashboard
export async function searchInDashboard(token, word) {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_SCRUM_BEE_API_URL}/api/issues/dashboard/search/?word=${word}`,
      {
        headers: {
          Authorization: token,
        },
        method: "GET",
      }
    )
    return await response.json()
  } catch (error) {
    throw error
  }
}

export async function getAllIssues(token) {
  try {
    const response = await fetch(`${process.env.REACT_APP_SCRUM_BEE_API_URL}/api/issues/`, {
      headers: {
        Authorization: token,
      },
      method: "GET",
    })

    return response.json()
  } catch (error) {
    throw error
  }
}
