import { Card, Col, Spin, Row } from "antd"

import getImage from "../getImage"

import "@fontsource/poppins"
import "./css/SingleProject.css"

export default function SingleProject({ project, loading, error }) {
  if (loading)
    return (
      <div className="header">
        Loading project infos... <Spin />
      </div>
    )

  // Error message
  if (!loading && error) return <p className="header">Error in fetching project...</p>

  if (!loading && project != null)
    return (
      <Row>
        <Col>
          <Card
            style={{
              backgroundImage: `url(${getImage(project.background)})`,
            }}
            className="imageCard"
          ></Card>
        </Col>
        <Col>
          <Card className="singleProjectCard">
            <ul>
              <li className="title">{project.name}</li>
              <li>
                Type: <b>{project.type}</b>
              </li>
              <hr />
              <li>
                <b>Link:</b> {project.documentationLink}
              </li>
              <li>
                <b>Description:</b> {project.description}
              </li>
            </ul>
          </Card>
        </Col>
      </Row>
    )
}
