// import { acceptFriend, refuseFriend } from "../services/friend";
import { Button } from "antd"
import { useSearchParams } from "react-router-dom"
import { acceptFriend, refuseFriend } from "../services/friends"

// CSS
import "@fontsource/poppins"
const buttonCSS = {
  width: "min-content",
  textAlign: "center",
}

export default function FriendInvitation() {
  const [searchParams] = useSearchParams()
  const userId = searchParams.get("idInvited")
  const friendId = searchParams.get("idInviter")

  const handleAccept = async () => {
    if (userId && friendId) {
      const response = await acceptFriend(userId, friendId)
      alert(response.data.message)
      return window.open("https://www.hive-stag.scrumbee.app/")
    }
  }

  const handleRefuse = async () => {
    if (userId && friendId) {
      const response = await refuseFriend(userId, friendId)
      alert(response.data)
      return window.open("https://www.hive-stag.scrumbee.app/")
    }
  }

  return (
    <>
      <div style={{ fontFamily: "Poppins", marginTop: "60px", textAlign: "center" }}>
        <h3>Vuoi accettare la richiesta di amicizia?</h3>
        <Button type="ghost" style={buttonCSS} onClick={handleAccept}>
          Si
        </Button>{" "}
        <Button type="ghost" style={buttonCSS} onClick={handleRefuse}>
          No
        </Button>
      </div>
    </>
  )
}
