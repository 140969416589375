import store from "../flux/store";
import { Input, Space } from "antd";
import { searchInDashboard } from "../services/issues";
const { Search } = Input;

export default function SearchInDash({ handleSearch }) {
  async function onSearch(word) {
    return handleSearch(
      await searchInDashboard(store.getState().auth.token, word)
    );
  }
  return (
    <Space direction="vertical">
      <Search
        placeholder="input search text"
        allowClear
        onSearch={onSearch}
        style={{
          width: 200,
        }}
      />
    </Space>
  );
}
