import axios from "axios"

const BASE_URL_TASK = "https://www.task-stag.scrumbee.app"
const TOKEN_BE_COMUNICATIONS =
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJjb21wYW55IjoiR2F0ZTQyc3JsIiwiYl9lbmRQcm9qTmFtZSI6InNjX2Jvb2tpbmdfYmUiLCJpYXQiOjE2Mzk3NzU2Mjh9.hQEqaGu6q6kdEpBvH2QEWHzN_8sNzzWdEvOwaVK6tbg"

export async function getAllTasks() {
  try {
    const response = await axios.get(`${BASE_URL_TASK}/task/tasks`, {
      headers: {
        Authorization: TOKEN_BE_COMUNICATIONS,
      },
    })

    if (response.status === 200) return response
    else throw new Error("Unable to retrieve all tasks from db...")
  } catch (error) {
    console.error(error)
    return error.request
  }
}
