import React from "react"
import { connect } from "react-redux"
import { useNavigate, useLocation } from "react-router-dom"

// Login logic import
import { logout } from "../flux/actions/authActions"

// Ant Design imports
import { Button } from "antd"
import { HomeOutlined, LogoutOutlined } from "@ant-design/icons"

// CSS and images
import bee from "../assets/img/bee.png"
import "./css/AppNavbar.css"

// Navabar component
const AppNavbar = ({ isAuthenticated, logout }) => {
  // Navigation hooks
  const navigate = useNavigate()
  const location = useLocation()

  return (
    <table className="navbar">
      <tbody>
        <tr>
          <td className="navImg">
            <img src={bee} alt="Scrum Bee logo" className="img"></img>
          </td>

          <td className="navTitle">
            <ul className="list">
              <li>Scrum bee</li>
              <li className="tester">{location.pathname === "/admin/" ? "Admin" : "Tester"}</li>
            </ul>
          </td>

          <td className="navLogout">
            <Button
              type="ghost"
              shape="round"
              style={{
                visibility: isAuthenticated === true ? "visible" : "hidden",
              }}
              icon={<LogoutOutlined />}
              onClick={() => {
                logout()
                navigate("/login/")
              }}
            >
              Logout
            </Button>
          </td>

          <td className="navHome">
            <Button
              type="ghost"
              shape="circle"
              icon={<HomeOutlined />}
              onClick={() => {
                navigate("/homepage/")
              }}
            />
          </td>
        </tr>
      </tbody>
    </table>
  )
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
})

export default connect(mapStateToProps, { logout })(AppNavbar)
