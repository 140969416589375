import React from "react";
import ReactDOM from "react-dom/client";
import { Await, BrowserRouter } from "react-router-dom";
import App from "./App";
import AsyncErrors from "./flux/AsyncErrors";
import ErrorBoundary from "./flux/ErrorBoundary";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <ErrorBoundary>
    <Await errorElement={AsyncErrors}>
      <BrowserRouter basename="/react-app">
        <App />
      </BrowserRouter>
    </Await>
  </ErrorBoundary>
);
