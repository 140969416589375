// External modules
import { useEffect, useState } from "react"
import { Table, Button, Modal, Form, Input, Typography, Space, Alert, Badge, Spin } from "antd"

// Services and user's info
import { deleteIssue, projectIssues, updateIssue } from "../services/issues"
import store from "../flux/store"

// Components
import { default as CommentsModal } from "./CommentsModal"
import SearchBar from "./SearchBar"

// CSS
import "./css/ProjectIssues.css"
import "./css/table.css"

// Ant components object
const { Title } = Typography
const { TextArea } = Input

export default function ProjectIssues({ issues, setIssues, projectMembers }) {
  // Project id
  const projectId = window.location.search.toString().substring(1, window.location.search.toString().length)

  // Error and loading hooks
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const [errorMsg, seterrorMsg] = useState(null)

  // Hooks to handle issue edit modal
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [modalData, setmodalData] = useState(false)
  const [commentsCount, setCommentsCount] = useState(0)

  // Hook to handle comments modal
  const [isCommentsModalOpen, setIsCommentsModalOpen] = useState(false)

  // Hooks to handle elements to show/hide on edit/save buttons click
  const [showIssueText, setShowIssueText] = useState("visible")
  const [showEditBox, setShowEditBox] = useState("hidden")
  const [showEditButton, setShowEditButton] = useState("visible")
  const [showSaveButton, setShowSaveButton] = useState("hidden")

  // hook to set table parameters
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 5,
    },
  })

  // Hook to handle edit modal form
  const [form] = Form.useForm() // Creates the form

  // function to toggle edit/save buttons
  const toggleEditSaveButtons = () => {
    if (showSaveButton === "hidden") {
      setShowSaveButton("visible")
      setShowEditButton("hidden")
    } else {
      setShowSaveButton("hidden")
      setShowEditButton("visible")
    }
  }

  // function to toggle text and form on edit button click
  const toggleTextForm = () => {
    if (showEditBox === "hidden") {
      setShowEditBox("visible")
      setShowIssueText("hidden")
    } else {
      setShowEditBox("hidden")
      setShowIssueText("visible")
    }
  }

  // VIEW MODAL FUNCTIONS
  const showModal = async () => setIsModalOpen(true)

  const handleSave = async (issue) => {
    seterrorMsg(null)
    if (!form.getFieldValue().description) return seterrorMsg("Description is required")

    if (Object.keys(form.getFieldValue()).length !== 0) {
      const reqBody = {
        description: form.getFieldValue().description,
      }

      // CALLS PUT ISSUE API
      await updateIssue(reqBody, store.getState().auth.token, issue._id)

      // updates issue to display
      setIssues(
        (
          await projectIssues(
            window.location.search.toString().substring(1, window.location.search.toString().length),
            store.getState().auth.token
          )
        ).issues
      )
    }

    // resets the form and closes the modal
    form.resetFields()
    setIsModalOpen(false)

    // toggles edit/save buttons and hides back the form
    toggleEditSaveButtons()
    toggleTextForm()
  }

  const handleDelete = async (issueId) => {
    // CALLS DELETE ISSUE API
    await deleteIssue(store.getState().auth.token, issueId)

    // Updates issue array to display
    setIssues(issues.filter((issue) => issue._id !== issueId))
  }

  const handleCancel = () => {
    // sets error msg to null, resets form, toggles both buttons and form (if needed), closes modal on cancel for issue edit modal
    seterrorMsg(null)
    form.resetFields()
    if (showEditBox === "visible") {
      toggleEditSaveButtons()
      toggleTextForm()
    }
    setIsModalOpen(false)
  }

  // COMMENTS MODAL FUNCTIONS
  const showCommentsModal = () => setIsCommentsModalOpen(true)

  const handleCommentsBack = (comments) => {
    // recalculates comments number and closes the modal
    setCommentsCount(comments.length)
    setIsCommentsModalOpen(false)
  }

  // SEARCH ISSUES
  const handleSearch = (childData) => setIssues(childData)

  // This fills the columns of the table
  const columns = [
    {
      title: "Author",
      key: "a",
      render: (payload) => {
        return `${payload.author.firstname} ${payload.author.lastname}`
      },
    },
    {
      title: "Title",
      key: "title",
      render: (payload) => {
        return `${payload.title}`
      },
      sorter: (a, b) => a.description.localeCompare(b.description),
    },
    {
      title: "Description",
      key: "description",
      render: (payload) => {
        return payload.description
      },
    },
    {
      title: "Assignees",
      key: "assignees._id",
      filters: projectMembers
        ? projectMembers
        : [
            {
              text: (
                <>
                  Loading assignees...
                  <Spin style={{ position: "absolute", left: "10px" }} />
                </>
              ),
            },
          ],
      onFilter: (value, record) => {
        for (const assignee of record["assignees"]) if (assignee._id === value) return true
      },
      render: (payload) => {
        return (
          <ul>
            {payload.assignees.map((item) => {
              return (
                <li key={item._id}>
                  {item.firstname} {item.lastname}
                </li>
              )
            })}
          </ul>
        )
      },
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
    },
    {
      title: "Priority",
      dataIndex: "priority",
      key: "priority",
      filters: [
        {
          text: "None",
          value: "toDefine",
        },
        {
          text: "Low",
          value: "normal",
        },
        {
          text: "Medium",
          value: "medium",
        },
        {
          text: "High",
          value: "high",
        },
      ],
      onFilter: (value, record) => record.priority.indexOf(value) === 0,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      filters: [
        {
          text: "Pending",
          value: "pending",
        },
        {
          text: "In progress",
          value: "inProgress",
        },
        {
          text: "Testing",
          value: "testing",
        },
        {
          text: "Done",
          value: "done",
        },
      ],
      onFilter: (value, record) => record.status.indexOf(value) === 0,
    },
    {
      title: "Created At",
      key: "createdAt",
      render: (payload) => {
        return new Date(payload.createdAt).toLocaleString()
      },
      sorter: (a, b) => a.createdAt > b.createdAt,
      defaultSortOrder: "descend",
    },
    {
      title: "Actions",
      key: "key",
      dataIndex: "key",
      render: (text, payload) => {
        return (
          <>
            <Space>
              <Button
                className="viewButton"
                type="ghost"
                shape="round"
                onClick={() => {
                  setCommentsCount(payload.comments.length)
                  showModal()
                  setmodalData(payload)
                }}
              >
                {"View"}
              </Button>
              <Modal
                title={<Title level={3}>{modalData.title}</Title>}
                open={isModalOpen}
                mask={false}
                onCancel={handleCancel}
                footer={[
                  <Button
                    className="cancelEditIssueButton"
                    key="Cancel"
                    type="default"
                    shape="round"
                    onClick={handleCancel}
                  >
                    {"Cancel"}
                  </Button>,
                  <Button
                    className="editButton"
                    type="ghost"
                    shape="round"
                    style={{ visibility: showEditButton }}
                    onClick={() => {
                      toggleTextForm()
                      toggleEditSaveButtons()
                    }}
                  >
                    {"Edit"}
                  </Button>,
                  <Button
                    className="saveEditIssueButton"
                    key="Save"
                    type="ghost"
                    shape="round"
                    style={{ visibility: showSaveButton }}
                    onClick={() => {
                      handleSave(modalData)
                    }}
                  >
                    {"Save"}
                  </Button>,
                ]}
              >
                {errorMsg ? <Alert message={errorMsg} type="error" /> : null}
                <p style={{ visibility: showIssueText }}>
                  <div className="descriptionLabel">
                    <b>Description: </b>
                  </div>
                  <p className="descriptionText">{modalData.description} </p>
                </p>
                <Form form={form} layout="horizontal" style={{ visibility: showEditBox }}>
                  <Form.Item
                    name="description"
                    label={<b>Description: </b>}
                    tooltip="Required"
                    labelCol={{ span: 24 }}
                    wrapperCol={{
                      span: 24,
                    }}
                  >
                    <TextArea
                      placeholder={modalData.description}
                      autoSize={{
                        minRows: 1,
                        maxRows: 8,
                      }}
                    />
                  </Form.Item>
                </Form>
                <p style={{ marginTop: "10px" }}>
                  <div>
                    <b>Type:</b> {modalData.type}
                  </div>
                  <div>
                    <b>Date:</b> {new Date(modalData.createdAt).toLocaleString()}
                  </div>
                </p>
                <div style={{ marginTop: "50px" }}>
                  <b>Comments</b>
                  <Button
                    className="commentsButton"
                    type="ghost"
                    shape="default"
                    onClick={() => {
                      showCommentsModal()
                    }}
                  >
                    {"View"}
                  </Button>
                  <Badge
                    style={{
                      position: "absolute",
                      bottom: "6px",
                      right: "-8px",
                    }}
                    count={commentsCount}
                  ></Badge>
                  <CommentsModal
                    isOpen={isCommentsModalOpen}
                    back={() => {
                      handleCommentsBack(modalData.comments)
                    }}
                    issue={modalData}
                    setIssues={setIssues}
                  />
                </div>
              </Modal>
              <>
                <Button
                  className="deleteButton"
                  type="ghost"
                  shape="round"
                  onClick={() => {
                    handleDelete(payload._id)
                  }}
                >
                  {"Delete"}
                </Button>
              </>
            </Space>
          </>
        )
      },
    },
  ]

  useEffect(() => {
    // This is the fetch of the data to put in columns
    const fetchIssues = async () => {
      // Fetching data
      const fetchedData = await projectIssues(projectId, store.getState().auth.token)

      if (fetchedData.status === "success") {
        // Successfull fetching
        setIssues(fetchedData.issues)
      } else {
        // Fetching error
        setError(true)
        console.error(fetchedData)
      }

      // Stopping the spinning
      setLoading(false)
    }

    fetchIssues()
  }, [setIssues, projectId])

  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams({
      pagination,
      filters,
      ...sorter,
    })

    if (pagination.pageSize !== tableParams.pagination?.pageSize) setIssues([])
  }

  if (loading)
    return (
      <div className="header">
        Loading issues... <Spin />
      </div>
    )

  // Error message
  if (!loading && error) return <p className="header">Error in fetching issues...</p>

  if (!loading && issues.length >= 0)
    return (
      <div>
        <SearchBar handleSearch={handleSearch} />
        <Table
          style={{ marginTop: "35px" }}
          dataSource={issues}
          columns={columns}
          rowKey="_id"
          size="small"
          pagination={tableParams.pagination}
          onChange={handleTableChange}
        />
      </div>
    )
}
