import { useSearchParams } from "react-router-dom"
import { Button } from "antd"

import "@fontsource/poppins"

export default function EmailVerification() {
  const [searchParams] = useSearchParams()
  const status = searchParams.get("status")

  if (status === "error")
    return (
      <>
        <div style={{ fontFamily: "Poppins", marginTop: "60px" }}>
          <h3>A problem occurred during your account verification. Please, try again.</h3>
        </div>
      </>
    )
  else
    return (
      <>
        <div style={{ fontFamily: "Poppins", marginTop: "60px" }}>
          <h3>Your account has been verified. You can now login into Scrum-BEE.</h3>

          <Button
            type="ghost"
            style={{
              width: "min-content",
              textAlign: "center",
              marginTop: "15px",
            }}
            onClick={() => {
              (status === "success")
                ? window.open("https://www.hive-stag.scrumbee.app/")
                : window.open("https://api-stag.scrumbee.app/react-app/")
            }}
            block
          >
            Go to Scrum-BEE
          </Button>
        </div>
      </>
    )
}
