import axios from "axios"
import { returnErrors } from "./errorActions"
import { AUTH_ERROR, LOGIN_SUCCESS, LOGIN_FAIL, LOGOUT_SUCCESS, REFRESH_SUCCESS } from "./types"

// Refresh token if it is expired
export const refreshToken = () => async (dispatch, getState) => {
  // Calls backend API responsable for refresh token into the session
  await axios
    .post(`${process.env.REACT_APP_SCRUM_BEE_API_URL}/auth/refreshtoken`, {
      refreshtoken: getState().auth.refreshToken,
    })
    .then((res) => {
      // dispatches the authentication action using the api response
      dispatch({
        type: REFRESH_SUCCESS,
        payload: res.data,
      })
    })
    .catch((err) => {
      // dispatches the error action using api response
      if (err.response.data) dispatch(returnErrors(err.response.data, err.response.status))
      // dispatches the authentication action to reset auth state
      dispatch({
        type: AUTH_ERROR,
      })
    })
}

// Login User
export const login =
  ({ email, password }) =>
  async (dispatch) => {
    // Headers
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    }

    // Request body
    const body = JSON.stringify({ email, password })

    // Calls backend API responsable for login
    const result = await axios
      .post(`${process.env.REACT_APP_SCRUM_BEE_API_URL}/auth/login`, body, config)
      .then((res) =>
        // dispatches the authentication action using the api response
        dispatch({
          type: LOGIN_SUCCESS,
          payload: res.data,
        })
      )
      .catch((err) => {
        // dispatches the error action using api response
        if (err.response.data) dispatch(returnErrors(err.response.data, err.response.status, "LOGIN_FAIL"))
        // dispatches the authentication action to reset auth state
        dispatch({
          type: LOGIN_FAIL,
        })
      })

    return result
  }

// Logout User
export const logout = () => {
  // dispatches the authentication action to reset auth state
  return {
    type: LOGOUT_SUCCESS,
  }
}
