import axios from "axios";
export const acceptFriend = async (userId, friendId) => {
  return await axios
    .get(
      `${process.env.REACT_APP_SCRUM_BEE_API_URL}/users/accept_friend/?idInvited=${userId}&idInviter=${friendId}`
    )
    .catch((error) => {
      if (error.response) {
        //bad request error
        console.error(error.response);
        return <p>{error.response.data}</p>;
      } else if (error.request) {
        console.error(error.request); // error when server doesn't respond
      } else {
        console.error(error.message); //unexpected error
      }
    });
};

export const refuseFriend = async (userId, friendId) => {
  return await axios
    .get(
      `${process.env.REACT_APP_SCRUM_BEE_API_URL}/users/refuse_friend/?idInvited=${userId}&idInviter=${friendId}`
    )
    .catch((error) => {
      if (error.response) {
        //bad request error
        console.error(error.response);
        return <p>{error.response.data}</p>;
      } else if (error.request) {
        console.error(error.request); // error when server doesn't respond
      } else {
        console.error(error.message); //unexpected error
      }
    });
};
